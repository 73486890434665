<template>
  <div class="container item-price">
    <div class="row">
      <div class="col-12 list">
        <div class="list-p">
          <b>{{ $t("lesson.item.basic_price") }} </b>
          <b>{{ formatCurrency(priceLesson.currency_code, priceLesson.price) }} {{ $t("lesson.included_tax") }}</b>
        </div>
      </div>
      <div class="col-12 list mt-3" v-if="priceLesson.items.length">
        <div class="item" v-for="(item, index) in priceLesson.items" :key="index">
          <div class="list-p">
            <div>
              <input type="checkbox" :id="`item-option-${item.id}`" @change="(e) => handlePrice(e, item)" />
              <label class="item-name" :for="`item-option-${item.id}`"> {{ item.name }} </label>
            </div>
            <b>{{ formatCurrency(priceLesson.currency_code, item.price) }} {{ $t("lesson.included_tax") }}</b>
          </div>
        </div>
      </div>
      <div class="col-12 list mt-3 purchase">
        <h4 class="price">{{ formatCurrency(price.currency, price.total) }} {{ $t("lesson.included_tax") }}</h4>
        <button class="text-center button-purchase" 
            @click="handleClickPurchase" 
            v-if="!_disablePurchased"
            :disabled="disabledBtn"
          >
          {{ $t("lesson.detail.reservation_screen") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabledBtn: false
    }
  },
  props: {
    data: Object,
    price: Object,
  },
  computed: {
    teacherInfo() {
      return this.data.teacher_info;
    },
    priceLesson() {
      return this.data.price_lesson;
    },
    _disablePurchased() {
      const memberId = this.getMemberId();
      return memberId == this.teacherInfo.member_id;
    },
  },
  methods: {
    handlePrice(e, item) {
      this.$emit("handlePrice", e, item);
    },
    handleClickPurchase() {
      if(this.disabledBtn)return;
      this.disabledBtn = true;
      this.$parent.handleClickPurchase();
      setTimeout(() => {
        this.disabledBtn = false;
      }, 10000);
    }
  },
}
</script>

<style></style>