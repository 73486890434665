<template>
  <div v-if="data">
    <div>
      <div class="info title" v-if="data.level">
          {{ $t(`lesson.${data.level}`) }}
      </div>
      <h2 class="title">{{ data.title }}</h2>
    </div>
    <div class="favorite">
      <Favorite
        :rate="data.rate"
        :favoriteCount="data.favorites_count"
        :lesson_id="data.id"
      />
    </div>
    <div>
      <LessonIntro :text="data.introduction" />
    </div>
    <div>
      <Thumbnail
        :thumbnail="data.preview.thumbnail"
        :image_1="data.preview.image_1"
        :image_2="data.preview.image_2"
      />
    </div>
    <div>
      <Price :data="data" :price="price" @handlePrice="handlePrice"/>
    </div>
    <div class="side-right">
      <SlotsAside :data="data" />
      <TeacherProfile :data="data" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Favorite from "./components/section/favorite.vue";
import LessonIntro from "./components/section/lesson-intro.vue";
import Thumbnail from "./components/section/thumbnail.vue";
import Price from "./components/section/price.vue";
import TeacherProfile from "./components/aside/teacher-profile.vue";
import SlotsAside from "./components/aside/slots.vue";

export default {
  data() {
    return {
      price: {
        total: 0,
        currency: "JPY"
      },
      selectedItemIds: [],
    }
  },
  props: {
    data: Object,
  },
  components: {
    Favorite,
    LessonIntro,
    Thumbnail,
    TeacherProfile,
    Price,
    SlotsAside,
  },
  created() {
    this.price.total = Number(this.priceLesson.price)
  },
  computed: {
    priceLesson(){
      return this.data.price_lesson;
    },
  },
  methods: {
    ...mapActions("lessonDetail", { 
      actionSetPopup: "setPopup",
      validateItems: "validateItems"
    }),
    async handleClickPurchase() {
      const memberId = this.getMemberId();
      if (memberId) {
        const response = await this.validateItems({
          lessonId: this.data.id,
          ids: this.selectedItemIds
        });
        window.location.href = `/purchase/${response.data.id}?mmspid=${this.data.mms_product_id}&mmstp=${this.price.total}`;
      } else {
        this.actionSetPopup()
      }
    },
    handlePrice(e, item) {
      if(e.target.checked){
        this.price.total += Number(item.price);
        this.selectedItemIds.push(item.id);
      }else {
        this.price.total -= Number(item.price);
        const index = this.selectedItemIds.indexOf(item.id);
        this.selectedItemIds.splice(index, 1);
      }
    }
  },
  watch: {
    "data": function (data) {
      this.selectedItemIds = [];
      this.price.total = Number(data.price_lesson.price);
    }
  }
};
</script>

<style>
</style>