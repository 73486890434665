<template>
  <div class="teacher-profile mt-0">
    <h3 class="title"> 
        {{ $t("lesson.detail.instructor") }}
    </h3>
    <div class="content">
      <div class="cimg">
        <img
          :src="teacherInfo.profile_image"
        />
      </div>
      <h3 class="name">
        {{ teacherInfo.name }}
      </h3>
      <p class="text-pre-line descr">
        {{ teacherInfo.introduction }}
      </p>
      <button class="view-profile" @click="handleClick">
        {{ $t("lesson.detail.teacher_profile") }}  <i class="fa fa-2x fa-angle-right" aria-hidden="true"></i>
      </button>
    </div>
    <button class="button-inquiry" style="min-width: 150px" @click="handleInquiry" v-if="!_disableInquiry">
      {{ $t("lesson.detail.contact_instructor") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: Object
  },
  computed: {
    ...mapGetters({
      actionSetPopup: "lessonDetail/setPopup"
    }),
    teacherInfo(){
      return this.data.teacher_info;
    },
    _disableInquiry() {
      const memberId = this.getMemberId();
      return memberId == this.teacherInfo.member_id || this.teacherInfo.allow_inquiry == 0;
    },
    
  },
  methods: {
    handleClick() {
        this.$router.push({ 
            name: 'teacher-profile', 
            params: { teacherId: this.teacherInfo.member_id }
          });
    },
    handleInquiry() {
      const memberId = this.getMemberId();
      if (memberId) {
        this.$router.push({
          name: 'inquiry-teacher',
          params: { lessonId: this.data.id }
        });
      } else {
        this.actionSetPopup()
      }
    },
  },
};
</script>

<style>
</style>